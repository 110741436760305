<div class="modal-header">
  <h5 class="modal-title heading-5-bold">
    Configuración tabla
  </h5>
  <button
    type="button"
    class="close"
    (click)="activeModal.dismiss('cross-button')"
  >
    <span class="material-symbols-outlined">close</span>
  </button>
</div>

<div class="modal-body">
  <form
    [formGroup]="myForm"
    (ngSubmit)="submitForm()"
    class="form-container"
  >
    <div class="columns-config">
      <div class="row">
        <div class="offset-1 col-6 form-group">
          <label disabled class="form-label">Titulo de Tabla</label>
          <input
            class="form-control"
            type="text"
            placeholder="Ingresa un nombre"
            formControlName="table_name"
          >
          
          <ng-container *ngIf="myForm.get('table_name')?.invalid && myForm.get('table_name')?.touched" class="row error-div">
            <div>
              <span class="material-symbols-outlined">error</span>
              <p class="error-message  ">Ingresar Titulo</p>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="scrollable-container mb-3" formArrayName="members">
        <ng-container *ngFor="let item of members().controls; let i = index; let first = first;" >
          <div class="row" [formGroupName]="i">
            <div class="col-1 form-group d-flex align-items-end">
              <ng-containter *ngIf="!first">
                <button type="button" class="btn btn-icon btn-icon-sm btn-icon-link delete" (click)="removeItems(i)">
                  <span class="material-symbols-outlined">
                    delete
                  </span>
                </button>
              </ng-containter>
            </div>
           
            <div class="col-4 form-group">
              <label class="form-label">
                Nombre Columna {{ i + 1  }}
              </label>
              <input
                type="text"
                placeholder="Ingresa un nombre"
                class="form-control"
                formControlName="column_name"
              >

              <ng-container *ngIf="members().controls[i].get('column_name')?.invalid && members().controls[i].get('column_name')?.touched">
                <div class="row error-div">
                  <p class="error-message">
                    <span class="material-symbols-outlined">error</span>Ingresar Nombre Columna
                  </p>
                </div>
              </ng-container>
            </div>
            
            <div class="col-4">
              <label class="form-label">Tipos</label>
              <ng-select
                [items]="attributeTypes"
                bindLabel="name"
                appendTo=".modal-body"
                formControlName="column_type"
              >
              </ng-select>

              <ng-container *ngIf="members().controls[i].get('column_type')?.invalid && members().controls[i].get('column_type')?.touched">
                <div class="row error-div">
                  <p class="error-message">
                    <span class="material-symbols-outlined">error</span> Seleccione un tipo
                  </p>
                </div>
              </ng-container>
            </div>
            
            <div class="col-3">
              <label class="form-label">&nbsp;</label>
              <label class="label">
                <input
                  type="checkbox"
                  class="custom-switch-input"
                  formControlName="switch"
                >
                <span class="custom-switch-indicator custom-radius"></span>
                Campo Referencia
              </label>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="members().controls.length < 6">
          <div class="row">
            <div class="col-auto offset-1">
              <button
                type="button"
                class="btn btn-link tx-body-2"
                (click)="addColumn()"
              >
                <span class="material-symbols-outlined">add</span>
                Agregar más columnas
              </button>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="form-buttons">
        <button
          class="btn btn-outline-primary"
          (click)="activeModal.dismiss('cancel-button')"
        >
          Cancelar
        </button>

        <button
          class="btn btn-secondary"
          type="submit"
        >
          Guardar
        </button>
      </div>
    </div>
      
    <ng-container *ngIf="anyReferenceEnabled">
      <div class="reference-config scrollable-container" formArrayName="members">
        <ng-container *ngFor="let select of members().controls; let i = index">
          <div [formGroupName]="i">
            <ng-container *ngIf="isReferenceEnabled( i )">
              <p class="tx-body-1-bold text-secondary-dark">Columna {{ i + 1 }}</p>

              <div class="form-group">
                <label class="form-label">
                  Entidad
                </label>

                <ng-select
                  formControlName="reference"
                  [items]="catalogues"
                  bindLabel="name"
                  bindValue="id"
                  appendTo=".modal-body"
                  (change)="handleReference( i )"
                >
                </ng-select>

                <ng-container *ngIf="members().controls[ i ].get('reference')?.invalid && members().controls[ i ].get('reference')?.touched">
                  <div class="row error-div">
                    <span class="material-symbols-outlined">error</span>
                    <p class="error-message">Seleccione una Entidad</p>
                  </div>
                </ng-container>
              </div>
              
              <div class="form-group">
                <label class="form-label">
                  Seccion
                </label>
                <ng-select
                  formControlName="sections"
                  [items]="sections[ i ] ?? []"
                  bindLabel="name"
                  bindValue="id"
                  appendTo=".modal-body"
                  (change)="handleSection( i )"
                >
                </ng-select>

                <ng-container *ngIf="members().controls[ i ].get('sections')?.invalid && members().controls[ i ].get('sections')?.touched">
                  <div class="row error-div">
                    <span class="material-symbols-outlined">error</span>
                    <p class="error-message">Seleccione una Seccion</p>
                  </div>
                </ng-container>
              </div>
              
              <div class="form-group">
                <label class="form-label">
                  Atributo
                </label>

                <ng-select
                  formControlName="attributes"
                  [items]="attributes[ i ] ?? []"
                  bindLabel="name"
                  bindValue="id"
                  appendTo=".modal-body"
                >
                </ng-select>

                <ng-container *ngIf="members().controls[ i ].get('attributes')?.invalid && members().controls[ i ].get('attributes')?.touched">
                  <div class="row error-div">
                    <span class="material-symbols-outlined">error</span>
                    <p class="error-message">Seleccione un Atributo</p>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </form>
</div>