<div class="toaster" [style.background-color]="_bgColor" [ngClass]="{'toast-medium_size': _mediumSize}">
  <div class="toast-icon" [class]="options.toastClass">
    <span
      class="material-symbols-outlined"
      [style.color]="_txLColor"
    >
      {{ _iconName  }}
    </span>
  </div>
  <div class="toast-details">
    <div>
      <div
        *ngIf="message && options.enableHtml"
        role="alert"
        aria-live="polite"
        class="custom-message"
        [style.color]="_txTColor"
        [class]="options.messageClass"
        [innerHTML]="message"
      >
      </div>
      <div 
        *ngIf="title && ( message && !options.enableHtml)"
        role="alert"
        aria-live="polite"
        class=""
        [attr.aria-label]="title + ' ' + message"
      >
        <span
          class=""
          [class]="options.titleClass"
          [style.color]="_txTColor"
          [attr.aria-label]="title"
          style="margin-right: 8px;"
        >
          {{ title }}
        </span>
        <span
          class=""
          [class]="options.messageClass"
          [style.color]="_txLColor"
          [attr.aria-label]="message"
        >
          {{ message }}
        </span>
      </div>
    </div>
  </div>
  <div class="close-icon">
      <!-- OWNS TO ANCHOR ELEMENT: class="toast-close-button" -->
    <a
      style="cursor: pointer;"
      *ngIf="options.closeButton"
      (click)="remove()"
    >
      <span
        class="material-symbols-outlined"
        [style.color]="_txTColor"
      >
        close
      </span>
    </a>
  </div>
</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
  