import { Injectable, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
// import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { CookieService } from 'ngx-cookie-service';
import firebase from 'firebase/compat/app';
import 'firebase/auth';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpResponse } from 'src/app/core/interfaces/http-response';
import { Observable, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SharedDataService } from '../shared-data/shared-data.service';

export interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {

  //Variables
  nombreLogin:any
  public phoneNumber: string = '';
  public companyID: string = '4';
  public userData: any;
  public user!: firebase.User;
  public showLoader: boolean = false;
  public email: string = '';
  public codeTimer: number = 0;
  public isUsingEmail: boolean = false;
  //URL
  private baseUrl: string = environment.urlApi;

  /* PATHS */
  public createUserPath: string = 'Users'
  private TextCode: string = 'VerificationCode'

  //URL
  //Paths
  public loginPath: string = 'Users/LoginUsers';
  phone: any;

  public token: string | undefined;


  constructor(public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    public toster: ToastrService,
    private cookieService: CookieService,
    private http: HttpClient,
    private sharedDataService: SharedDataService,
  ) {

    // this.afAuth.authState.subscribe((user: any) => {
    //   if (user) {
    //     this.userData = user;
    //     cookieService.set('user', JSON.stringify(this.userData));
    //     localStorage.setItem('user', JSON.stringify(this.userData));
    //     // JSON.parse(localStorage.getItem('user'));
    //   } else {
    //     localStorage.setItem('user', null || '{"user_id":"12819231"}');
    //     // JSON.parse(localStorage.getItem('user'));
    //   }
    // });
  }

  ngOnInit(): void { }

  async getMasterToken(): Promise<string> {
    if (!environment.appUser || !environment.appPassword) {
      throw new Error('Application user not defined.');
    }

    const body = {
      email: environment.appUser,
      password: environment.appPassword
    };

    try {
      const response = await lastValueFrom(this.http.post<any>(environment.urlAuth, body));
      if (response && response.data && response.data.token) {
        this.nombreLogin=response.data.name
        this.token = response.data.token as string;
        this.sharedDataService.setUserToken(this.token);
        return this.token;
        
      } else {
        throw new Error('Token no recibido'); // Lanza un error si el token no se recibe
      }

      
    } catch (error) {
      console.error('Error al obtener el token:', error);
      throw error;
    }
  }

  getInformation() {
    return this.nombreLogin
  }

  public setToken(token: string) {
    this.token = token;
  };

  public setUsingEmail(value: boolean) {
    this.isUsingEmail = value;
  };

  // sign in function
  // Función para iniciar sesión con email
  SignInEmail(email: string) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.token}`
    });

    const body: any = {
      email: this.email,
    }

    return lastValueFrom(this.http.post(this.baseUrl + this.loginPath, body, { headers: headers }));
  }

  // Función para iniciar sesión con teléfono
  SignInPhone(phone: any) {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`
    });

    const body: any = {
      phone: phone,
      password: phone,
    }

    return lastValueFrom(this.http.post(this.baseUrl + this.loginPath, body, { headers: headers }));
  }

  SignUser(user: string, password: string) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.token}`
    });

    const body: any = {
      username: user,
      password: password,
    }
    console.log(body)
    return lastValueFrom(this.http.post(this.baseUrl + this.loginPath, body, { headers: headers }));
  }


  getQuestions(user:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`
    });

    const body : any = {
      UserId: user
    }

    return lastValueFrom(this.http.post(this.baseUrl + 'SecurityQuestion/GetUserQuestions', body, { headers: headers }));
  }

  getQuestionsUserName(user:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`
    });

    const body : any = {
      username: user
    }

    return lastValueFrom(this.http.post(this.baseUrl + 'SecurityQuestion/GetQuestionsByUsername', body, { headers: headers }));
  }

  validateQuestion(data:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`
    });

    return lastValueFrom(this.http.post(this.baseUrl  + 'SecurityQuestion/Validate', data, { headers: headers }));
  }


  SignUp(phone: any, countryCode: any): Observable<any> {
    const body: any = { phone: phone, countryCode: countryCode };
    return this.http.post(this.baseUrl + this.createUserPath, body);
  }

  changePassword(data:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`
    });

    return lastValueFrom(this.http.post(this.baseUrl + 'Users/Reset', data, { headers: headers }));
    
  }


  SaveUser(id:any, firstName: any, lastName: any, dateDay: any, genero: any) {
    const body: any = {
      id: id,
      firstName: firstName,
      lastName: lastName,
      dateDay: dateDay,
      genero: genero,
      countrycode: '502'
    }
    return this.http.patch(this.baseUrl + this.createUserPath, body);
  }

  //Sends verification code via text messsage
  SendVerificationText(phone: any, code: any) {
    const body: any = {
      phone: this.phoneNumber,
      countrycode: '502',
      VerCode: code
    }
    return lastValueFrom(this.http.post(this.baseUrl + this.TextCode, body)).then((response: HttpResponse) => {
      return response;
    }).catch((error) => {
      throw error
    })
  }
  //Sends verification code via text messsage
  ReSendVerificationText(phone: any, code: any) {
    const body: any = {
      phone: this.phoneNumber,
      countrycode: '502',
    }
    return lastValueFrom(this.http.post(this.baseUrl + this.TextCode, body)).then((response: HttpResponse) => {
      return response;
    }).catch((error) => {
      throw error
    })
  }
  ReSendVerificationTextEmail(phone: any, code: any) {
    const body: any = {
      email: this.email,
      countrycode: '502',
    }
    return lastValueFrom(this.http.post(this.baseUrl + this.TextCode, body)).then((response: HttpResponse) => {
      return response;
    }).catch((error) => {
      throw error
    })
  }

  VerifyCode(phone: any, code: any) {
    const body: any = {
      phone: this.phoneNumber,
      countrycode: '502',
      VerCode: code
    }
    return lastValueFrom(this.http.patch(this.baseUrl + this.TextCode, body)).then((response: HttpResponse) => {

      return response;
    }).catch((error) => {
      throw error
    })
  }

  VerifyEmailCode(email: any, code: any) {
    const body: any = {
      email: email,
      VerCode: code
    }
    return lastValueFrom(this.http.patch(this.baseUrl + this.TextCode, body)).then((response: HttpResponse) => {

      return response;
    }).catch((error) => {
      throw error
    })
  }

  // main verification function
  SendVerificationEmail() {
    const body: any = {
      email: this.email,
    }
    return lastValueFrom(this.http.post(this.baseUrl + this.TextCode, body)).then((response: HttpResponse) => {
      return response;
    }).catch((error) => {
      throw error
    })
  }

  // Sign in with Facebook
  signInFacebok() {
    return this.AuthLogin(new firebase.auth.FacebookAuthProvider());
  }

  // Sign in with Twitter
  signInTwitter() {
    return this.AuthLogin(new firebase.auth.TwitterAuthProvider());
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new firebase.auth.GoogleAuthProvider());
  }

  ForgotPassword(passwordResetEmail: any) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      }).catch((error: any) => {
        window.alert(error);
      });
  }

  // Authentication for Login
  AuthLogin(provider: any) {
    return this.afAuth.signInWithPopup(provider)
      .then((result: any) => {
        this.ngZone.run(() => {
          this.router.navigate(['/dashboard/dashboard01']);
        });
        this.SetUserData(result.user);
      }).catch((error: any) => {
        window.alert(error);
      });
  }

  // Set user
  SetUserData(user: any) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      email: user.email,
      displayName: user.displayName,
      uid: user.uid,
      photoURL: user.photoURL || 'src/favicon.ico',
      emailVerified: user.emailVerified
    };
    userRef.delete().then(function () { })
      .catch(function (error: any) { });
    return userRef.set(userData, {
      merge: true
    });
  }

  // Sign out
  SignOut() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    return this.afAuth.signOut().then(() => {
      this.showLoader = false;
      localStorage.clear();
      this.cookieService.deleteAll('user', 'login');
      this.router.navigate(['login']);
    });
  }



  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return (user != null && user.emailVerified != false) ? true : false;
  }

  setPhoneNumber(phone: string) {
    this.phoneNumber = phone;
  }

  setCompanyID(id: string) {
    this.companyID = id;
  }

  setEmail(email: string) {
    this.email = email;
  }

  setCodeTimer(time: number) {
    this.codeTimer = time;
  }

  saveUserData(object: any): void {
    if (object.status_ingreso == 1) {
      // TODO: Almacenar las propiedades del usuario
    }
    this.sharedDataService.setUserId(object?.id)

    this.sharedDataService.setSystemToken(object.tokenSistema);
  }
}
