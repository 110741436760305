import { Routes } from '@angular/router';


export const content: Routes = [
  {
    path: 'catalogue',
    loadChildren: () => import('../../pages/catalogue/catalogue.module').then(m => m.CatalogueModule)
  },
  {
    path: '',
    loadChildren: () => import('../../pages/ffa/itinerary/itinerary.module').then(m => m.ItineraryModule)
  },
  {
    path: 'activities',
    loadChildren: () => import('../../pages/ffa/activities/activities.module').then(m => m.ActivitiesModule)
  },
  {
    path: 'roles',
    loadChildren: () => import('../../pages/ffa/roles/roles.module').then(m => m.RolesModule)
  },
  {
    path: 'users',
    loadChildren: () => import('../../pages/ffa/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'profile-portfolio',
    loadChildren: () => import('../../pages/ffa/profile-portfolio/profile-portfolio.module').then(m => m.ProfilePortfolioModule)
  },
  {
    path: 'reason',
    loadChildren: () => import('../../pages/ffa/reason/reason.module').then(m => m.ReasonModule)
  },
  {
    path: 'sales',
    loadChildren: () => import('../../pages/ffa/sales/sales.module').then(m => m.SalesModule)
  },
  {
    path: 'wms',
    loadChildren: () => import('../../pages/wms/wms.module').then(m => m.WmsModule)
  },
  {
    path: 'customers',
    loadChildren: () => import('../../pages/ffa/customers/customers.module').then(m => m.CustomersModule)
  },
  {
    path: 'prices',
    loadChildren: () => import('../../pages/ffa/prices/prices.module').then(m => m.PricesModule)
  },
  {
    path: 'payment-condition',
    loadChildren: () => import('../../pages/ffa/customers-payment-condition/customers-payment-condition.module').then(m => m.CustomersPaymentConditionModule)
  },
  {
    path: 'combos',
    loadChildren: () => import('../../pages/ffa/combos/combos.module').then(m => m.CombosModule)
  },

  {
    path: 'promotions',
    loadChildren: () => import('../../pages/ffa/promotions/promotions.module').then(m => m.PromotionsModule)
  },
  {
    path: 'vikingo',
    loadChildren: () => import('../../pages/vikingo/vikingo.module').then(m => m.VikingoModule)
  },
  {
    path: 'min-amount',
    loadChildren: () => import('../../pages/ffa/customers-monto-minimo/customers-monto-minimo.module').then(m => m.CustomersMontoMinimoModule)
  },

];
