import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, lastValueFrom, Subject} from 'rxjs';
import { HttpResponse } from 'src/app/core/interfaces/http-response';
import {
  Attribute,
  Detail,
  Section,
  Type,
  Catalogue,
  Size,
  FieldClass,
  FielTypeRule,
  Tag,
  Label,
  FormulaClass
} from 'src/app/pages/catalogue/data/interfaces';
import { AuthService } from 'src/app/shared/services/firebase/auth.service';
import { environment } from 'src/environments/environment';
import { CompanyService } from 'src/app/pages/company/company.service';
import { SharedDataService } from '../shared-data/shared-data.service';
import { ToastService } from '../toast.service';
import { TableModalComponent } from 'src/app/pages/catalogue/components/table-modal/table-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { is } from 'date-fns/locale';

@Injectable({
  providedIn: 'root'
})
export class CatalogueService {

  /* Services urls */
  private baseUrl    : string = environment.catalogueConfigUrl;
  private baseUrlv3  : string = `${environment.catalogueConfigUrl}/v3/Catalog`;
  private createDBUrl: string = `${environment.catalogueCreationUrl}/v2/Create/table`;
  private insertDBUrl: string = `${environment.catalogueCreationUrl}/v2/Entity`;

  /* Services paths */
  private fieldTypePath    : string = '/v2/Config/field-type';
  private sectionTypePath  : string = '/v1/Config/section-type';
  private rulePath         : string = '/v1/Config/rule';
  private fieldTypeRulePath: string = '/v2/Config/field-type-rule';
  private organizationsPath: string = '/v1/Config/workspace';
  private helpTypePath     : string = '/v1/HelpType';

  private organizationName: string = '';
  private isUpdating: boolean = false;
  private isEditing: boolean = false;

  /* Configuration or Administration mode  */
  private _configOrAdmin: boolean = false;

  private isUpdatingCataloguePreview: boolean = false;

  private selectedSectionData: any;

  /* Configuration Variables */
  private _catalogue: Catalogue = {};
  private _selectedSection: number = -1;
  private _selectedAttribute: number = -1;
  private _sectionTypes: Type[] = [];
  private _attributeTypes: Type[] = [];
  public _fielTypeRules : FielTypeRule[] = [];
  public subCatalogReference: any = {};
  public entityRecord: any | null = null;
  public workspaceTags: Tag[] = [];
  public readonly decimalsDefault: number = 4;

  /* Value emitters */
  public attributeTypes$ = new Subject<Type[]>();
  public sectionTypes$   = new Subject<Type[]>();
  public catalogue$      = new Subject<Catalogue>();
  public selectedAttribute$ = new Subject<number>();

  private selectedSectionSubject: BehaviorSubject<number> = new BehaviorSubject<number>(this._selectedSection);
  public selectedSection$: Observable<number> = this.selectedSectionSubject.asObservable();

  private isModalActive: boolean = false;

  constructor(
    private httpClient    : HttpClient,
    private authService   : AuthService,
    private companyService: CompanyService,
    private sharedServoce : SharedDataService,
    private toastService  : ToastService,
    private ngbModal      : NgbModal,
  ) { }

  public get attributeTypes(): Type[] {
    return this._attributeTypes;
  }

  public set attributeTypes( value: Type[] ) {
    this._attributeTypes = value;
    this.attributeTypes$.next(this._attributeTypes);
  }

  public get sectionsTypes(): Type[] {
    return this._sectionTypes;
  }

  public set sectionsTypes(value: Type[]) {
    this._sectionTypes = value;
    this.sectionTypes$.next( this._sectionTypes );
  }

  public get catalogue(): Catalogue {
    return this._catalogue;
  }

  public set catalogue(value: Catalogue) {
    this._catalogue = value;
    this.catalogue$.next(this._catalogue);
  }

  public get selectedSection(): number {
    return this._selectedSection;
  }

  public set selectedSection(value: number) {
    this._selectedSection = value;
  }

  public get selectedAttribute(): number {
    return this._selectedAttribute;
  }

  public set selectedAttribute(value: number) {
    this._selectedAttribute = value;
    this.selectedAttribute$.next(this._selectedAttribute);
  }

  public get configOrAdmin(): boolean {
    const configOrAdmin = sessionStorage.getItem('configOrAdmin');
    if  ( !configOrAdmin ) {
      this._configOrAdmin = false;
      sessionStorage.setItem('configOrAdmin', 'false');
    }
    else {
      this._configOrAdmin = configOrAdmin === 'true';
    }
    
    return this._configOrAdmin;
  }

  public set configOrAdmin( value: boolean ) {
    sessionStorage.setItem('configOrAdmin', value ? 'true' : 'false');
    this._configOrAdmin = value;
  }

  public setIsUpdating(value: boolean): void {
    this.isUpdating = value;
  }

  public getIsUpdating(): boolean {
    return this.isUpdating;
  }

  public  getisEditing(): boolean {
    return this.isEditing;
  }
  public  setisEditing(value: boolean) {
    this.isEditing = value;
  }

  public setisUpdatingCataloguePreview(value: boolean): void {
    this.isUpdatingCataloguePreview = value;
  }

  public getisUpdatingCataloguePreview(): boolean {
    return this.isUpdatingCataloguePreview;
  }

  setModalActive(isActive: boolean): void {
    this.isModalActive = isActive;
  }

  getIsModalActive(): boolean {
    return this.isModalActive;
  }

  addNewSection( type: Type ): void {
    let pivot = this.catalogue.sections || [];
    const newOrder = pivot.length + 1;

    pivot.push({
      name      : `Seccion ${ newOrder }`,
      type      : type,
      status    : 1,
      order_form: newOrder,
      attributes: []
    });

    this._catalogue.sections = pivot;
    this.catalogue = this._catalogue;
    this.changeSelectedSection( pivot.length - 1 );
    this.changeSelectedAttribute( pivot.length - 1, 0 );
  }

  removeSection(index: number): void {
    const pivot = this.catalogue;

    if (
      pivot
      && pivot.sections
      && index >= 0
      && index < pivot.sections.length
      && pivot.sections[ index ]
    ) {
      if (
        !pivot.sections.some(( a: Section ) =>
          a.status == 1
          && a.id != pivot.sections![ index ].id
          && a.attributes!.length > 0
          && a.attributes!.some(( b: Attribute ) => b.status == 1 )
        )
      ) {
        this.toastService.showWarningToast(
          'Eliminar sección',
          'No se puede eliminar la última sección con atributos',
          'highlight'
        );
        return;
      }

      if (
        pivot.sections[ index ].attributes
        && pivot.sections![ index ].attributes!.length
        && pivot.sections![ index ].attributes!.some(( a: Attribute) => a.field![0].detail?.some(( a: Detail ) => a.rule?.name === 'unique' && a.value === 'true' ) )
      ) {
        this.toastService.showWarningToast(
          'Eliminar sección',
          'No se puede eliminar una sección con atributos con la regla único',
          'highlight'
        );
        return;
      }

      if (
        pivot.sections[ index ].id != null
      ) {
        pivot.sections[ index ].status = 0;
      }
      else {
        pivot.sections.splice( index, 1 );
      }

      this.catalogue = pivot;
    }
    else {
      console.error("Intento de eliminar una sección con un índice no válido o catálogo no inicializado");
    }
  }

  createCatalogue(catalogue: Catalogue, companyId: string): Promise<HttpResponse> {
    return lastValueFrom(this.httpClient.post<HttpResponse>(this.baseUrlv3, catalogue));
  }

  updateCatalogue( catalogue: Catalogue ): Promise<any> {
    return lastValueFrom( this.httpClient.put( this.baseUrlv3, catalogue) );
  }

  validateCatalogue(catalogue: Catalogue): boolean {
    if (catalogue.name == null || catalogue.name.length == 0) {
      return false;
    }

    if (catalogue.sections != null) {
      if (catalogue.sections.length > 0) {
        const flag = catalogue.sections.some((a) => a.name == null || a.name.length == 0 || a.type == null);
        if (!flag) return false;
      }
    }

    return true;
  }

  updateCatalogueName(name: string): void {
    let pivot = this.catalogue;
    pivot.name = name;
    this.catalogue = pivot;
  }

  updateSection(index: number, section: Section): void {
    if (this._catalogue && this._catalogue.sections && index >= 0 && index < this._catalogue.sections.length) {
        this._catalogue.sections[index] = {...this._catalogue.sections[index], ...section};
        this.catalogue$.next(this._catalogue);
    } else {
      console.error('Intento de actualizar una sección con un índice no válido o sin catálogo inicializado');
    }
  }

  addTemplateField( item: any, fieldClass?: FieldClass): void {
    const pivot = this.catalogue;

    if ( !pivot.sections || !pivot.sections[ this._selectedSection ] ) {
      this.toastService.showWarningToast(
        'Agregar atributo',
        'No se puede agregar un atributo a una sección inexistente o no seleccion',
      );
      return;
    }

    const attributeType = item.name === 'Empresa' || item.name === "Pais"
      ? { id: 2, name: 'De sistema' }
      : item.name === 'Tabla'
        ? { id: 3, name: 'Detalle' }
        : { id: 1, name: 'Simple' };

      
    if ( item.name === 'Tabla' ) {
        const tableAttribute = pivot.sections
        .find(( section ) => {
          return section.attributes
            ?.find(( attribute ) => attribute.type?.name === 'Detalle' );
        });

        if ( tableAttribute ) {
          this.openTableModal();
          return;
        }
    }

    const orderForm = pivot.sections[this._selectedSection].attributes?.length
      ? pivot.sections[this._selectedSection].attributes!.length + 1
      : 1;

    const newAttribute: Attribute = {
      status: 1,
      orderForm: orderForm,
      type: attributeType,
      help: null,
      object_sql_generate: null,
      id_catalog: null,
      size: {
        id: '1',
        name: 'Pequeño',
        value: 'col-4'
      },
      field: attributeType.id != 3
        ? [
          {
            name: attributeType.id == 2
              ? item.name
              : 'Atributo',
            type: item,
            id: null,
            help: null,
            idTemplate: 1,
            idCatalog: fieldClass
              ? fieldClass.idCatalog
              : undefined,
            idCatalogAttribute: fieldClass
              ? fieldClass.idCatalogAttribute
              : undefined,
          }
        ]
        : [],
      name: `Atributo ${ orderForm }`
    };

    if ( attributeType.id !== 3 ) {
      newAttribute.field![0].detail = [];
    }
    
    pivot.sections[ this._selectedSection ].attributes!.push( newAttribute );

    this.catalogue = pivot;

    if ( item.name == 'Tabla' ) {
      this.openTableModal();
    }
    this.selectedAttribute = orderForm - 1;
  }

  openTableModal(): void {
    const modalRef = this.ngbModal.open(
      TableModalComponent,
      {
        size    : 'lg',
        centered: true,
        backdrop: 'static',
        keyboard: false
      }
    );
  }


  updateAttribute(
    name: string,
    details: Detail[],
    status: number,
    size?: Size,
    placeholder?: string,
    definition?: string,
    daysDue?: number,
    label?: Label[],
    formula?: FormulaClass
  ): void {
    let   catalogue = this.catalogue;
    const section   = catalogue.sections?.[ this.selectedSection ];

    if ( section && section.attributes && this.selectedAttribute < section.attributes.length ) {
      let attribute = section.attributes[ this.selectedAttribute ];
      
      if ( attribute ) {
        attribute.name = name;
        attribute.help = definition;
        attribute.days_due = daysDue ?? 0;
        
        if ( `${attribute.type.id}` === '1' && attribute.field ) {
          attribute.field.forEach(( fieldItem: FieldClass ) => {
            fieldItem.detail = fieldItem.detail
              ?.filter(( detail: Detail ) => detail.id != null )
              .map(( detail: Detail ) => {
                if ( !detail.status ) {
                  return detail;
                }

                if ( details.some(( a: Detail ) => a.id === detail.id ) ) {
                  return details.find(( a: Detail ) => a.id === detail.id )!;
                }

                return {
                  ...detail,
                  status: 0
                }
              })
              .concat( details.filter(( detail: Detail ) => detail.id == null ) );
            fieldItem.help = placeholder;
            fieldItem.formula = formula;
          });
        }

        attribute.status = status;
        if ( size ) {
          attribute.size = size;
        }

        if ( label && label.length ) {
          attribute.label = label;
        }

        catalogue.sections![ this.selectedSection ].attributes![ this.selectedAttribute ] = attribute;
        this.catalogue = catalogue;
      }
    }
  }

  deleteAttribute( indexSection: number, indexAttribute:number ): void {
    if ( indexSection < this.catalogue.sections!.length ) {
      if ( indexAttribute < this.catalogue.sections![ indexSection ].attributes!.length ) {
        let pivCat = this.catalogue;
        let pivAtt = pivCat.sections![ indexSection ].attributes!;

        /* Validacion existencia de atributos */
        if (
          !this.catalogue.sections
            ?.some(( a: Section ) =>
                a.attributes!.length > 0
                && a.attributes
                  ?.some(( b: Attribute ) =>
                    b.status == 1
                    && b.id != pivAtt[ indexAttribute ].id
                  )
            )
        ) {
          this.toastService.showWarningToast(
            'Eliminar atributo',
            'No se puede eliminar el último atributo de una sección',
            'highlight'
          );
          return; 
        }

        /* Validacion regla unico */
        if (
          pivAtt[ indexAttribute ].id != null
          && pivAtt[ indexAttribute ].field
          && pivAtt[ indexAttribute ].field![0].detail
        ) {

          if (
            pivAtt[ indexAttribute ].field![0].detail
              ?.some( detail =>
                detail.rule!.name === 'unique'
                && detail.value === 'true'
              )
          ) {
            this.toastService.showWarningToast(
              'Eliminar atributo',
              'No se puede eliminar un atributo con la regla único',
              'highlight'
            );
            return;
          }
          else {
            pivAtt[ indexAttribute ].status = 0;
          }

        }
        
        if ( pivAtt[ indexAttribute ].id == null ) {
          pivAtt.splice( indexAttribute, 1 );
        }
        
        pivCat.sections![ indexSection ].attributes = pivAtt;
        this.catalogue = pivCat;
      }
    }
  }

  public changeSelectedAttribute( iSection: number, iAttribute: number | null): void {
    this.selectedSection = iSection;
    if ( iAttribute === null ) {
      this.selectedAttribute = -1;
    }
    else if (
      this.catalogue.sections![ iSection ]
      && this.catalogue.sections![ iSection ].attributes![ iAttribute ]
      && this.catalogue.sections![ iSection ].attributes![ iAttribute ].status == 1
    ) {
      this.selectedAttribute = iAttribute;
    }
    else {
      this.selectedAttribute = this.catalogue.sections![ iSection ].attributes
        ?.findIndex( a => a.status == 1 )
        ?? -1;
    }
  }

  changeSelectedSection( index: number ): void {
    this.selectedSection = index;
  }

  clearCatalogue(): void {
    this.catalogue = {};
    this.isUpdating = false;
  }

  updateSectionStatus(index: number, status: number): void {
    if (this._catalogue && this._catalogue.sections && index >= 0 && index < this._catalogue.sections.length) {
      const section = this._catalogue.sections[index];
      section.status = status;

      if ( !status ) {
        section!.attributes!.forEach(attribute => {
          attribute.status = 0;
        });
      }

      this.catalogue$.next( this._catalogue );
    } else {
      console.error("Intento de actualizar el estado de una sección con un índice no válido o catálogo no inicializado");
    }
  }

  notifyAttributeFormResetNeeded() {
    this.selectedAttribute$.next(-1);
  }

  setSelectedSectionData(data: any): void {
    this.selectedSectionData = data;
  }

  getSelectedSectionData(): any {
    return this.selectedSectionData;
  }

  addPermissionsToSection( index: number, permissions: any[] ): void {
    if (this._catalogue && this._catalogue.sections && index < this._catalogue.sections.length) {
      let pivot = this._catalogue;

      pivot.sections![ index ].permissions = permissions;

      this.catalogue = pivot;
    }
  }

  removePermissionFromSection( index: number ): void {
    if ( this.catalogue && this.catalogue.sections && index < this.catalogue.sections.length ) {
      this.catalogue.sections[ index ].permissions = []
    }
  }

  /* HTTP REQUESTS */
  getFieldTypes(): void {
    const body: any = {
      Input: {
        Accion: 'R'
      }
    };

    lastValueFrom(this.httpClient.post(this.baseUrl + this.fieldTypePath, body))
      .then((response: HttpResponse) => {
        console.log('GET FIELD TYPES', response);
        if (response.status == 200 && response.data != null) {
          this.attributeTypes = response.data.rows;
        } else {
          this.attributeTypes = [];
        }
      })
      .catch((error) => {
        console.error('GET FIELD TYPES ERROR', error);
        this.attributeTypes = [];
      });
  }

  getSectionTypes(): void {
    const body: any = {
      Input: {
        Accion: 'R'
      }
    }

    lastValueFrom(this.httpClient.post(this.baseUrl + this.sectionTypePath, body))
      .then((response: HttpResponse) => {
        console.log('GET SECTION TYPES', response);
        if (response.status == 200 && response.data != null) {
          this.sectionsTypes = response.data.rows;
        } else {
          this.sectionsTypes = [];
        }
      })
      .catch((error) => {
        console.error('GET SECTION TYPES ERROR', error);
        this.sectionsTypes = [];
      });
  }

  getRules(): void {
    const body: any = {
      Input: {
        Accion: 'R'
      }
    };

    lastValueFrom(this.httpClient.post(this.baseUrl + this.rulePath, body))
      .then((response: HttpResponse) => {
        console.log('GET RULES', response);
      })
      .catch((error) => {
        console.error('GET RULES ERROR', error);
      });
  }

  getFieldTypeRules(): void {
    lastValueFrom( this.httpClient.get( this.baseUrl + this.fieldTypeRulePath ) )
      .then(( response: HttpResponse ) => {
        console.log('GET FIELD TYPE RULES', response);
        if ( response.status == 200 && response.data && response.data.length ) {
          this._fielTypeRules = response.data;
        }
        else {
          this._fielTypeRules = [];
        }
      })
      .catch(( error: HttpResponse | any ) => {
        console.error('GET FIELD TYPE RULES ERROR', error);
        this._fielTypeRules = [];
      });
  }

  async getCountries(): Promise<any> {
    try {
      const response = await lastValueFrom(this.httpClient.get<any>(environment.catalogueConfigUrl + '/v2/Config/countries'));
      return response.data.rows; // 
    } catch (error) {
      console.error('Error get Country:');
      throw error;
    }
  }

  getDataCatalogue( name: string, select?: string, id?: number ): Promise<any> {
    let params = new HttpParams();
    params = params.append('clientDbName', 'leche_australian' ); // Todo:  Cambiar obtención de la DB
    params = params.append('tableName', name.replace(/\s+/g, '_') );
    
    if ( select ) {
      params = params.append('select', select.replaceAll(/\s+/g, '_'));
    }

    if ( id ) {
      params = params.append('id', id );
    }

    return lastValueFrom( this.httpClient.get( environment.catalogueCreationUrl + '/v2/Crud', { params: params } ) );
  }

  insertDataCatalogue( name: string, body: any ): Promise<any> {
    let params = new HttpParams();
    params = params.append('clientDbName', 'leche_australian' ); // Todo:  Cambiar obtención de la DB
    params = params.append('tableName', name.replace(/\s+/g, '_') );

    return lastValueFrom(
      this.httpClient.post(
        environment.catalogueCreationUrl + '/v2/Crud',
        body,
        { params: params }
      )
    );
  }

  updateDataCatalogue( name: string, id: number, body: any ): Promise<any> {
    let params = new HttpParams();
    params = params.append('clientDbName', 'leche_australian' ); // Todo:  Cambiar obtención de la DB
    params = params.append('tableName', name.replace(/\s+/g, '_') );
    params = params.append('id', id );

    return lastValueFrom(
      this.httpClient.put(
        environment.catalogueCreationUrl + '/v2/Crud',
        body,
        { params: params }
      )
    );
  }

  deleteDataCatalogue( name: string, id: number ): Promise<any> {
    let params = new HttpParams();
    params = params.append('clientDbName', 'leche_australian' ); // Todo:  Cambiar obtención de la DB
    params = params.append('tableName', name.replace(/\s+/g, '_') );
    params = params.append('id', id );

    return lastValueFrom(
      this.httpClient.delete(
        environment.catalogueCreationUrl + '/v2/Crud',
        { params: params }
      )
    );
  }

  saveCatalogue(catalogue: Catalogue, companyId: string): Promise<HttpResponse> {
    if (this.getIsUpdating()) {
      const catalogueCopy: Catalogue = { ...catalogue };
      console.log('Saving Catalogue:', JSON.stringify(catalogueCopy, null, 2));


      return lastValueFrom(this.httpClient.put<HttpResponse>(this.baseUrlv3, catalogueCopy));
    }
    return Promise.reject('Not in update mode');
  }

  getCatalogueById( id: number ): Promise<any> {
    let params = new HttpParams();
    params = params.append( 'Catalog', id );

    return lastValueFrom( this.httpClient.get( this.baseUrlv3 + '/id', { params: params }));
  }

  getCataloguesByCompany(): Promise<any> {
    let params = new HttpParams();
    params = params.append( 'company', this.authService.companyID );

    return lastValueFrom( this.httpClient.get( this.baseUrlv3 + '/company', { params: params }) );
  }

  getCataloguesByCompanySmall(): Promise<any> {
    let params = new HttpParams();
    params = params.append( 'companyId', this.authService.companyID );

    return lastValueFrom( this.httpClient.get( this.baseUrlv3 + '/all', { params: params }) );
  }

  getOrganizations(): Promise<HttpResponse> {
    const body: any = {
      Input: {
        Accion: 'R',
        Id: null,
        Name: null,
      }
    };

    return lastValueFrom(this.httpClient.post<HttpResponse>(this.baseUrl + this.organizationsPath, body))
      .then(response => {
        console.log('GET ORGANIZATIONS', response);
        return response;
      })
      .catch(error => {
        console.error('ERROR IN GET ORGANIZATIONS', error);
        throw error;
      });
  }

  createCatalogueDB( id: number ): Promise<any> {
    let params = new HttpParams();
    params = params.append('companyId', this.authService.companyID),
    params = params.append('catalogId', id );
    
    return lastValueFrom(this.httpClient.get(this.createDBUrl, { params: params }));
  }

  insertCatalogueDB(attributeValues: { [key: string]: any }): Promise<any> {
    const body: any = {};
    Object.keys(attributeValues).forEach(name => {
      body[name] = attributeValues[name];
    });
    const catalogueNameWithoutSpaces = this.catalogue.name!.replace(/\s+/g, '');
    return lastValueFrom(this.httpClient.post(`${this.insertDBUrl}/${catalogueNameWithoutSpaces}?db=${this.companyService.getCompanyName().replace(' ', '_')}`, body));
  }

  readCatalogueDB(): Promise<any> {
    const catalogueNameWithoutSpaces = this.catalogue.name!.replace(/\s+/g, '');
    return lastValueFrom(this.httpClient.get(`${this.insertDBUrl}/${catalogueNameWithoutSpaces}?db=${this.companyService.getCompanyName().replace(' ', '_')}`));
  }

  updateCatalogueDB(attributeValues: { [key: string]: any }): Promise<any> {
    const body: any = {};
    Object.keys(attributeValues).forEach(name => {
      body[name] = attributeValues[name];
    });
    const catalogueNameWithoutSpaces = this.catalogue.name!.replace(/\s+/g, '');
    return lastValueFrom(this.httpClient.post(`${this.insertDBUrl}/${catalogueNameWithoutSpaces}/update?db=${this.companyService.getCompanyName()}`, body));
  }

  updateregisterStatusDB(id: string, status: boolean): Promise<any> {
    const body: any = {
      Id: id,
      status: status ? '1' : '0'
    };
    const catalogueNameWithoutSpaces = this.catalogue.name!.replace(/\s+/g, '_');
    const dbNameWithUnderscores = this.companyService.getCompanyName().replace(/\s+/g, '_');
    return lastValueFrom(this.httpClient.post(`${this.insertDBUrl}/${catalogueNameWithoutSpaces}/update?db=${dbNameWithUnderscores}` , body));
  }

  getAttributeSizes(): Promise<any> {
    return lastValueFrom( this.httpClient.get<any>(`${this.baseUrl}/v2/Config/a-sizes`) );
  }

  getWorkspaceTags( callback?: VoidFunction ): void {
    let params = new HttpParams();
    const workspaceId: string | null = this.sharedServoce.getWorkspaceId();

    if ( workspaceId == null ) {
      this.workspaceTags = [];
      return;
    }

    params = params.append('id_workspaces', workspaceId );

    lastValueFrom( this.httpClient.get(`${ this.baseUrl}${ this.helpTypePath }`, { params: params }) )
      .then(( response: HttpResponse ) => {
        console.log('GET WORKSPACE TAGS', response);
        if ( response.status == 201 && response.data && response.data.length ) {
          this.workspaceTags = response.data;
        }
        else {
          this.workspaceTags = [];
        }
      })
      .catch(( error: any ) => {
        console.error('GET WORKSPACE TAGS ERROR', error);
        this.workspaceTags = [];
      });
  }

  getWorkspaceTagsPromise(): Promise<any> {
    let params = new HttpParams();
    const workspaceId: string | null = this.sharedServoce.getWorkspaceId();

    if ( workspaceId == null ) {
      return Promise.resolve([]);
    }

    params = params.append('id_workspaces', workspaceId );

    return lastValueFrom( this.httpClient.get(`${ this.baseUrl}${ this.helpTypePath }`, { params: params }) );
  }

  createWorkspaceTag( tag_name: string ): Promise<any> {
    const bodyObject = {
      help_type_name: tag_name,
      id_workspaces : parseInt( this.sharedServoce.getWorkspaceId() ?? '0' )
    } 

    return lastValueFrom( this.httpClient.post(`${ this.baseUrl }${ this.helpTypePath }`, bodyObject) )
  }

  updateWorkspaceTag( tag_id: number, tag_name: string  ): Promise<any> {
    const bodyObject = {
      id            : tag_id,
      help_type_name: tag_name,
      id_workspaces : parseInt( this.sharedServoce.getWorkspaceId() ?? '0' )
    }

    return lastValueFrom( this.httpClient.patch(`${ this.baseUrl }${ this.helpTypePath }`, bodyObject) );
  }

  postTranslate( body: any ): Promise<any> {
    return lastValueFrom( this.httpClient.post(`${ this.baseUrl }/v2/Mapping`, body ) );
  }

  patchTranslate( id: number, label: string ): Promise<any> {
    const body = {
      id   : id,
      label: label
    };

    return lastValueFrom( this.httpClient.patch(`${ this.baseUrl }/v1/Mapping`, body ) );
  }

  deleteTranslate( id: string ): Promise<any> {
    let params = new HttpParams();
    params = params.append('id', id );
    return lastValueFrom( this.httpClient.delete(`${ this.baseUrl }/v1/Mapping`, { params: params }) );
  }

}
