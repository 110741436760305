import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TableAttribute } from 'src/app/pages/catalogue/data/interfaces';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CatalogueService } from 'src/app/shared/services/catalogue/catalogue.service';
import { HttpResponse } from 'src/app/core/interfaces/http-response';

@Component({
  selector: 'app-table-modal',
  templateUrl: './table-modal.component.html',
  styleUrls: ['./table-modal.component.scss'],
})
export class TableModalComponent implements OnInit, OnDestroy {

  /* Form */
  myForm!: FormGroup;

  /* Select Dataset */
  catalogues: any[] = [];
  sections  : any = {};
  attributes: any = {};

  dataAttributesColumns: BehaviorSubject<any[]>[] = [];
  dataSectionsColumns: BehaviorSubject<any[]>[] = [];

  /* Main form variables */
  attributeTypes: any[] = [];
  selectedColumData: any[] = [];

  /* Subscription */
  typesSub: Subscription | undefined;

  public originalTableData: any;
  public tableAttributes: TableAttribute[] = [];
  public tableName: string = '';
  public savedData: any;

  constructor(
    private formBuilder     : FormBuilder,
    private catalogueService: CatalogueService,
    public  activeModal     : NgbActiveModal
  ) { }
  
  /* LIFE CYCLE */
  ngOnInit() {
    this.createForm();
    this.getCatalogues();

    if ( !this.catalogueService.attributeTypes.length ) {
      this.catalogueService.getFieldTypes();
      this.typesSub = this.catalogueService.attributeTypes$.subscribe((types: any) => {
        if ( types ) {
          this.attributeTypes = types
            .filter((a: any) => a.name !== 'Tabla');
        }
      });
    }
    else {
      this.attributeTypes = this.catalogueService
        .attributeTypes
        .filter((a: any) => a.name !== 'Tabla');
    }
  }

  ngOnDestroy(): void {
    this.typesSub?.unsubscribe();
  }

  /* METHODS */
  createForm(): void {
    this.myForm = this.formBuilder.group({
      table_name: new FormControl(''),
      members   : this.formBuilder.array([]),
    });
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      column_name: ['', Validators.required],
      column_type: ['', Validators.required],
      switch     : [false],
      reference  : [null, null],
      sections   : [null, null],
      attributes : [null, null], 
    });
  }

  members(): FormArray {
    return this.myForm.get('members') as FormArray;
  }

  get anyReferenceEnabled(): boolean {
    return this.members()
      .controls
      .some(( control: any ) => control.get('switch')?.value );
  }

  isReferenceEnabled( index: number ): boolean {
    return this.members().at( index ).get('switch')?.value;
  }

  handleReference( index: number = 0 ): void {
    const reference = this.members().controls[ index ].get('reference')?.value;
    this.members().controls[ index ].get('sections')?.reset({ value: null });

    if ( reference != null ) {
      this.sections[ index ] = this.catalogues
        .find(( catalogue: any ) => catalogue.id == reference )
        .sections ?? [];
    }
    else {
      this.sections[ index ] = [];
    }
  }

  handleSection( index: number = 0 ): void {
    const control   = this.members().controls[ index ];
    const reference = control.get('reference')?.value;
    const sections  = control.get('sections')?.value;

    if ( sections != null && reference != null ) {
      if ( !this.attributes[ index ] ) {
        this.attributes[ index ] = [];
      }

      this.attributes[ index ] = this.catalogues
        .find(( catalogue: any ) => catalogue.id == reference )
          .sections
            .find(( section: any ) => section.id == sections )
            .attributes ?? [];
    } else {
      this.attributes[ index ] = [];
    }
  }

  addColumn(): void {
    if ( this.members().length >= 6 ) return;

    this.members().push( this.createItem() );
  }

  submitForm(): void {
    console.log('Data:', this.myForm.value);
  }

  removeItems(index: number): void {
    this.members().removeAt(index);
  }

  getMemberGroup(index: number): FormGroup {
    return this.members().at(index) as FormGroup;
  }

  resetSections(index: number): void {
    const control = this.members().controls[index];
    control.get('sections')?.reset({ value: 0, disabled: true });
    this.dataSectionsColumns[index]?.next([]);
  }

  resetAtributte(index: number): void {
    const control = this.members().controls[index];
    control.get('attributes')?.reset({ value: 0, disabled: true });
    this.dataAttributesColumns[index]?.next([]);
  }

  closeModal(): void {
    this.myForm.reset();
    this.members().clear();
  }

  /* HTTP REQUESTS */
  getCatalogues(): void {
    this.catalogueService.getCataloguesByCompanySmall()
      .then(( response: HttpResponse ) => {
        console.log('GET CATALOGUES SMALL', response);
        if ( response.status == 200 && response.data ) {
          this.catalogues = response.data;
        }
      })
      .catch(( error: any ) => {
        console.error('GET CATALOGUES SMALL ERROR:', error);
      });
  }
}
